import React, { useState, useEffect } from "react";
import { useAuth } from "../../AuthContext";
import EmotionIcon from "../../components/EmotionIcon";
import axios from "axios";
import { MdDelete } from "react-icons/md";
import { MdPayment } from "react-icons/md";
import { toast } from "react-toastify";
import { getCookieValue } from "../../utils/manageCookie";
import { formattedDate } from "../../utils/helpfulFunctions";
import { BASE_USER_URL, BASE_PAYMENT_URL } from "../../utils/APIURLs";
import CustomToolTipButton from "../../components/CustomToolTipButton";


const CourseCart = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { cartSize, updateCartSize, cartItems, getCartItems, isLoggedIn, userDetails } =
    useAuth();
  const [selectedItems, setSelectedItems] = useState([]);
  const [paymentStart, setPaymentStart] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      setSelectedItems(cartItems.courses);
    }
  }, [cartItems]);

  const handleCheckboxToggle = (item) => {
    if (selectedItems.some((selectedItem) => selectedItem.slotId === item.slotId)) {
      setSelectedItems(
        selectedItems.filter((selectedItem) => selectedItem.slotId !== item.slotId)
      );
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };


  const initiatePayment = async (amount) => {
    const authToken = getCookieValue("authToken");
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    try {
      const res = await axios.post(`${BASE_PAYMENT_URL}`, {
        email: userDetails.email,
        amount: amount,
      }, config);

      if (res.status === 200) {
        window.location.href = res.data;
      }
    } catch (error) {
      const errorMessage = "Failed to buy course";
      toast.error(errorMessage);
    }
  }

  const subtotal = selectedItems && selectedItems.reduce(
    (acc, item) => acc + item.price,
    0
  );

  const handleDelete = (itemId, slotId) => async () => {
    const authToken = getCookieValue("authToken");
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };
    if (authToken) {
      const res = await axios.post(
        `${BASE_USER_URL}/removeEntityFromCart`,
        { itemId, slotId },
        config,
      );
    } else {
      const cartItems = JSON.parse(localStorage.getItem("cartItems"));
      const updatedCartItems = cartItems.filter(
        (item) => item._id !== itemId && item.slotId !== slotId,
      );
      localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
    }
    updateCartSize(cartSize - 1);
    getCartItems();
    toast.success("Course removed from cart successfully");
  };

  const handleBuyCartItems = async () => {
    const authToken = getCookieValue("authToken");
    const config = {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    };

    try {
      const res = await axios.post(
        `${BASE_USER_URL}/buyEntity`,
        { items: selectedItems, email: userDetails.email },
        config,
      );
      if (res.status === 200) {
        const response = res.data;
        console.log(response)
        const updatedItems = selectedItems.map((item) => {
          return { ...item, amount: item.price , cartPresent: true };
        })
        localStorage.setItem("buyItems", JSON.stringify(updatedItems));
        initiatePayment(subtotal);
        // setSelectedItems(cartItems.)
      }
    }
    catch (error) {
      toast.error("Failed to buy courses");
    }
  }

  useEffect(() => {
    getCartItems();
  }, [isOpen]);

  return (
    <div className="tw-container md:tw-mx-auto tw-mx-2 tw-my-8 tw-flex tw-flex-col tw-items-center tw-shadow-2xl tw-pb-20 tw-bg-lightest ">
      <div>
        <h1 className="tw-text-4xl tw-font-bold">Cart</h1>
      </div>
      {cartItems.courses.length === 0 ? (
        <div className="tw-flex tw-flex-col tw-mx-auto tw-items-center tw-my-10">
          <EmotionIcon name="sad" />
          <h1 className="tw-text-2xl tw-font-bold tw-my-4 tw-text-primary">
            Cart is Empty!
          </h1>
        </div>
      ) : (
        <>
          {isLoggedIn && (
            <h3 className="tw-text-2xl tw-font-bold tw-mt-10">
              Subtotal ({selectedItems.length} items) : ₹{" "}
              {subtotal}
            </h3>
          )}
        </>
      )}
      {cartItems.courses.length > 0 && (
        <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 xl:tw-grid-cols-3 tw-gap-4 tw-mx-2 tw-mt-10  tw-pt-20 tw-pb-10 md:tw-px-20 tw-px-5 ">
          {cartItems.courses.map((item) => (
            <div
              key={item.slotId}
              className="tw-p-3 md:tw-p-6 tw-rounded-lg tw-flex tw-flex-row  tw-items-center tw-shadow-2xl tw-bg-lightest"
            >
              <div className="tw-relative tw-flex tw-flex-col tw-space-y-4 tw-mr-4 sm:tw-w-2/5">

                <input type="checkbox" className="custom-checkbox tw-absolute tw-top-0 tw-left-0"
                  checked={selectedItems.some(
                    (selectedItem) => selectedItem.slotId === item.slotId)}
                  onChange={() => handleCheckboxToggle(item)}
                />

                <img
                  src={item.entityImage}
                  alt={item.title}
                  className="tw-rounded-full tw-w-40 tw-h-40"
                />
                <div className="tw-flex tw-flex-row  tw-space-x-3 tw-mx-auto ">
                  <CustomToolTipButton content="Delete" placement="bottom">
                    <button
                      onClick={handleDelete(item._id, item.slotId)}
                      className="tw-bg-accent tw-text-white tw-py-2 tw-px-4 tw-rounded-lg tw-mr-2"
                    >
                      <MdDelete />
                    </button>
                  </CustomToolTipButton>
                </div>
              </div>
              <div className="tw-flex tw-flex-col tw-iteme-left">
                <h4 className="tw-text-secondary tw-font-bold tw-mb-2">
                  {item.title}
                </h4>
                <h5 className="tw-text-primary tw-font-bold tw-mb-2">
                  {item.subtitle}
                </h5>
                <p className="tw-mb-2 tw-text-accent tw-text-left tw-font-medium">
                  <span className="tw-text-primary tw-font-bold">Duration:</span>{" "}
                  {formattedDate(item.startDate)} -{" "}
                  {formattedDate(item.endDate)}
                </p>
                <p className="tw-mb-2">
                  <span className="tw-text-primary tw-font-bold">
                    Category:
                  </span>{" "}
                  {item.courseCategoryTitle}
                </p>
                <p className="tw-mb-2">
                  <span className="tw-text-primary tw-font-bold">
                    Remaining Seats:
                  </span>{" "}
                  {item.remainingSeats}
                </p>
                <p className="tw-mb-2">
                  <span className="tw-text-primary tw-font-bold">Price:</span> ₹{" "}
                  {item.price}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
      <div>
        {cartSize > 0 && (
          <>
            {!paymentStart && (
              <button
                className={`tw-bg-accent tw-font-bold tw-w-full tw-text-xl tw-text-primary tw-py-3 tw-shadow-2xl hover:tw-text-black  tw-px-4 tw-rounded-lg tw-mt-4 tw-mr-4 ${isLoggedIn ? "" : "tw-opacity-50 tw-cursor-not-allowed"}`}
                onClick={() => setPaymentStart(true)}
                disabled={!isLoggedIn}
              >
                Proceed to Buy {cartSize} items
              </button>
            )}

            {paymentStart && (
              <button
                className="tw-bg-accent tw-font-bold tw-w-full tw-text-xl tw-text-black tw-py-3 tw-shadow-2xl hover:tw-text-black  tw-px-4 tw-rounded-lg tw-mt-4 tw-mr-4"
                onClick={handleBuyCartItems}
              >
                <MdPayment className="tw-mx-auto " />
                Pay ₹ {subtotal}
              </button>

            )}
          </>
        )}
      </div>
      {/* {isOpen && (
        <BuyModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          slotId={buyDetails.slotId}
          itemId={buyDetails.itemId}
          price={buyDetails.price}
          courseCategory={buyDetails.courseCategory}
        />
      )} */}
    </div>
  );
};

export default CourseCart;
