// CourseDetail.js
import React, { useEffect, useState } from "react";
import { FaAngleDoubleDown } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../AuthContext";
import DownloadProspectus from "../../components/DownloadProspectus";
import { formattedDate } from "../../utils/helpfulFunctions";
import richTextRender from "../../utils/richTextRender";
import Faq from "../FAQs/Faq";
import ChooseSlot from "./ChooseSlot";

const CourseDetail = () => {
  const { title, subtitle } = useParams();
  const { allCourses, isLoggedIn } = useAuth();
  const [courseDetails, setCourseDetails] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [buttonType, setButtonType] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (allCourses.length > 0) {
      const course = allCourses.find((cor) => {
        if (subtitle === undefined) {
          return cor.title === title;
        } else {
          return cor.title === title && cor.subtitle === subtitle;
        }
      });
      console.log(title, subtitle, course);
      setCourseDetails(course);
    }
  }, [allCourses]);

  const addToCartHandle = () => {
    setIsOpen(true);
    setButtonType("cart");
  };

  const handleApplyNow = () => {
    if (isLoggedIn) {
      setIsOpen(true);
      setButtonType("apply");
    } else {
      // Show a message to the user
      toast.error("Please login to Apply!");
    }
  };

  const [showApplyButton, setShowApplyButton] = useState(true);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const applyButtonThreshold = 500; // Adjust this value based on your layout

    setShowApplyButton(scrollPosition < applyButtonThreshold);
  };

  const handleDownloadProspectus = () => {
    const url =
      "https://assets.ctfassets.net/fxuhbm32k7qv/5xuEEOg2JFsqU8aGwfsvaf/e98955fda728078546fce44a975c1985/HFolds_Brochure_Folder_1.pdf";
    window.open(url, "_blank");
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className=" tw-mx-auto">
      {isOpen && (
        <ChooseSlot
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          itemId={courseDetails._id}
          buttonType={buttonType}
          timeSlots={courseDetails.timeSlots}
          price={courseDetails.price}
          courseCategory={courseDetails.courseCategory}
        />
      )}
      <div className="tw-relative md:tw-h-[480px] tw-h-[50vh] md:tw-h-screen">
        <img
          src={
            courseDetails?.entityImage
              ? courseDetails.entityImage
              : "https://via.placeholder.com/1920x1080"
          }
          alt={courseDetails?.title}
          //className="tw-w-full md:tw-h-full tw-h-screen tw-object-cover tw-object-center  !important"
          className="tw-w-full tw-h-full tw-object-cover tw-object-center !important"
        />
        <div className="tw-absolute tw-bottom-16  tw-right-1/2 tw-transform tw-translate-x-1/2 tw-translate-y-1/2 tw-text-center">
          {courseDetails &&
            courseDetails.timeSlots &&
            courseDetails.timeSlots.length > 0 && (
              <div
                className="tw-flex md:tw-flex-row  tw-flex-col tw-justify-between"
                data-aos="fade-up"
              >
                <button
                  onClick={handleApplyNow}
                  className="tw-border tw-border-4 tw-border-double tw-px-6 tw-py-3 tw-bg-accent hover:tw-bg-opacity-50
                          hover:tw-border-accent tw-text-white tw-rounded-full md:tw-w-48 tw-w-36 tw-text-md md:tw-text-xl mr-4"
                >
                  Apply Now
                </button>
                {/*{isLoggedIn && (
                  <button
                    onClick={handleApplyNow}
                    className="tw-border tw-border-4 tw-border-double tw-px-6 tw-py-3 tw-bg-accent hover:tw-bg-opacity-50
                              hover:tw-border-accent tw-text-white tw-rounded-full md:tw-w-48 tw-w-36 tw-text-md md:tw-text-xl mr-4"
                  >
                    Apply Now
                  </button>
                )}*/}
                <button
                  onClick={addToCartHandle}
                  className="tw-border tw-border-4 tw-border-double tw-px-6 tw-py-3 md:tw-ml-4 md:tw-mt-0 tw-mt-4
                    tw-bg-secondary hover:tw-bg-opacity-50 hover:tw-border-secondary tw-text-white tw-rounded-full md:tw-w-48 tw-w-36 tw-text-md md:tw-text-xl"
                >
                  Add to Cart
                </button>
              </div>
            )}
          <div
            className="tw-absolute tw-mt-4 tw-right-1/2 tw-transform tw-translate-x-1/2 tw-text-center tw-cursor-pointer"
            onClick={() =>
              window.scrollTo({ top: window.innerHeight, behavior: "smooth" })
            }
          >
            <FaAngleDoubleDown className="tw-text-4xl tw-text-secondary" />
          </div>
        </div>
      </div>
      <div className="tw-container tw-mx-auto tw-p-8" data-aos="fade-up">
        {/* Description */}
        <div className="tw-text-center tw-mb-4">
          <h1 className="tw-text-4xl tw-font-bold tw-mb-4">
            {courseDetails?.title}
            {courseDetails?.subtitle !== "" && (
              <span className="tw-text-accent">
                {" "}
                - {courseDetails?.subtitle}
              </span>
            )}
          </h1>
          <p className="tw-text-xl">{courseDetails?.description}</p>
        </div>
        <hr />
        {courseDetails?.courseCategory &&
          courseDetails?.courseCategory?.title !== "Our Services" && (
            <>
              <div className="tw-flex md:tw-flex-row tw-flex-col">
                <div
                  className={`tw-flex tw-flex-col ${courseDetails?.title === "Master Classes" ? "md:tw-w-full" : "md:tw-w-2/3"} tw-justify-center`}
                >
                  {courseDetails.title ===
                    "6-Week Course in Bakery & Pastry" && (
                    <div className="tw-mt-32 md:tw-px-16 tw-px-4 tw-flex tw-flex-col">
                      <DownloadProspectus />
                      {/*<button
                    onClick={handleDownloadProspectus}
                    className={`tw-border tw-border-4 tw-border-double tw-text-xl tw-px-6
                     tw-py-3 tw-bg-secondary hover:tw-bg-transparent  hover:tw-border-accent hover:tw-text-primary tw-text-white tw-rounded-full tw-w-38 tw-transition-opacity `}
                  >
                    Download Prospectus
                  </button>*/}
                    </div>
                  )}
                  {/* fees of the course */}
                  {courseDetails.title !== "Master Classes" ? (
                    <>
                      <div className="tw-mt-8 md:tw-px-16 tw-px-4 tw-flex tw-flex-col">
                        <h5 className="tw-text-2xl tw-font-bold tw-mb-4 tw-text-center">
                          <span className="tw-text-secondary ">
                            Course Fees (Inclusive of GST):{" "}
                          </span>{" "}
                          ₹ {courseDetails?.price}
                        </h5>
                      </div>
                      <div className="tw-mt-8 md:tw-px-16 tw-px-4 tw-flex tw-flex-col">
                        {courseDetails &&
                        courseDetails.upComingSlots &&
                        courseDetails.upComingSlots.length > 0 ? (
                          <h5 className="tw-text-2xl tw-font-bold tw-mb-4 tw-text-center">
                            All Upcoming Slots
                          </h5>
                        ) : (
                          <h5 className="tw-text-3xl tw-font-bold tw-mb-4 tw-text-red-300">
                            We don't have any scheduled upcoming slots. Please
                            check back in a few days for available slots.
                          </h5>
                        )}
                        <div className="tw-flex tw-flex-col" data-aos="fade-up">
                          {courseDetails.upComingSlots &&
                            courseDetails.upComingSlots.map((item, index) => (
                              <div
                                key={index}
                                className="tw-text-center tw-border tw-border-4 tw-border-accent tw-border-double tw-text-xl tw-px-6 tw-py-3 tw-bg-transparent tw-text-accent tw-rounded-full tw-w-38 tw-mr-4 tw-mb-4 tw-transition-opacity"
                              >
                                {formattedDate(item.slot.startDate)} -
                                {formattedDate(item.slot.endDate)}
                              </div>
                            ))}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="tw-mt-8 md:tw-px-16 tw-px-4 tw-flex tw-flex-col tw-w-full">
                        <h2 className="tw-text-red-900 tw-font-bold tw-mb-4 tw-text-center">
                          Schedule to be announced shortly.
                        </h2>
                      </div>
                    </>
                  )}
                </div>
                <div
                  className={`tw-flex tw-flex-col ${courseDetails?.title === "Master Classes" ? "" : "md:tw-w-2/3"}`}
                >
                  {courseDetails && courseDetails?.faqs && (
                    <Faq data={courseDetails?.faqs} type="course" />
                  )}
                </div>
              </div>

              <div
                className="tw-mx-auto tw-flex tw-flex-col md:tw-p-16 tw-p-4"
                data-aos="fade-up"
              >
                {courseDetails?.extraInfo &&
                  richTextRender(courseDetails?.extraInfo)}
                {(courseDetails?.title === "6-Week Course in Bakery & Pastry" ||
                  courseDetails?.title ===
                    "One Month Intensive Egg Free Course in Bakery & Pastry") && (
                  <img
                    src="https://images.ctfassets.net/fxuhbm32k7qv/6CspbnrJcrlKkMwEOl24ec/127b6f12d494886c7a4e13178665122e/White_BG_200_200.png"
                    alt="logo"
                    className=" tw-w-32 tw-h-32 tw-mt-2 tw-cursor-pointer"
                  />
                )}
              </div>
            </>
          )}

        {courseDetails?.courseCategory &&
          courseDetails?.courseCategory.title === "Our Services" && (
            <div
              className="tw-text-center tw-text-3xl tw-my-4 tw-text-primary"
              data-aos="fade-up"
            >
              Interested ? <br />
              <button
                className="tw-border tw-border-4 tw-border-double tw-px-4 tw-py-2 tw-bg-accent hover:tw-bg-transparent hover:tw-border-accent tw-mx-3 tw-my-3"
                onClick={() => {
                  navigate(
                    `/contact-us/${courseDetails.title}/${courseDetails.subtitle}`,
                  );
                }}
              >
                Contact Us
              </button>
              <br />
              with your requirments
            </div>
          )}
      </div>

      {courseDetails &&
        courseDetails.timeSlots &&
        courseDetails.timeSlots.length > 0 && (
          <div
            className={`tw-flex tw-flex-col ${
              !showApplyButton ? "tw-opacity-100" : "tw-opacity-0"
            }`}
          >
            <button
              onClick={handleApplyNow}
              className={`tw-border tw-border-4 tw-border-double  tw-fixed tw-bottom-32 tw-right-8 tw-px-6
                           tw-py-3 tw-bg-accent hover:tw-bg-transparent  hover:tw-border-accent hover:tw-text-primary tw-text-white tw-rounded-full md:tw-w-48 tw-w-36 tw-text-md md:tw-text-xl tw-transition-opacity `}
            >
              Apply Now
            </button>
            {/*
            {isLoggedIn && (
              <button
                onClick={handleApplyNow}
                className="tw-border tw-border-4 tw-border-double tw-px-6 tw-py-3 tw-bg-accent hover:tw-bg-opacity-50
                          hover:tw-border-accent tw-text-white tw-rounded-full md:tw-w-48 tw-w-36 tw-text-md md:tw-text-xl mr-4"
              >
                Apply Now
              </button>
            )}*/}
            <button
              onClick={addToCartHandle}
              className="tw-border tw-border-4 tw-border-double tw-fixed tw-bottom-48 
                      tw-right-8 tw-px-6 tw-py-3 tw-bg-secondary hover:tw-bg-transparent  hover:tw-border-secondary hover:tw-text-primary tw-text-white tw-rounded-full md:tw-w-48 tw-w-36 tw-text-md md:tw-text-xl
                      tw-transition-opacity "
            >
              Add to Cart
            </button>
          </div>
        )}
    </div>
  );
};
export default CourseDetail;
