import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CiMenuBurger } from "react-icons/ci";
import { FaShoppingCart } from "react-icons/fa";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { useAuth } from "../AuthContext";
import AdminNavbar from "../admin/components/AdminNavbar";
import MenuSideBar from "./MenuSidebar";
import CustomToolTipButton from "./CustomToolTipButton";
import "./styles/MenuNavbar.css";

const NavbarComponent = () => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [aboutDropdownOpen, setAboutDropdownOpen] = useState(false);
  const [tourDropdownOpen, setTourDropdownOpen] = useState(false);
  const [faqDropdownOpen, setFaqDropdownOpen] = useState(false);
  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const [hoveredCategory, setHoveredCategory] = useState("");
  // const [languageButton, setLanguageButton] = useState(false);
  const [hoveredCourses, setHoveredCourses] = useState({});
  const {
    isLoggedIn,
    categories,
    cartSize,
    logout,
    userRole,
    allCourses,
    userDetails,
    courseMapping
  } = useAuth();

  const menuRef = useRef(null);
  const dropdownRef = useRef(null);
  const aboutDropdownRef = useRef(null);
  const tourDropdownRef = useRef(null);
  const faqDropdownRef = useRef(null);
  const profileDropdownRef = useRef(null);
  const closeOpenMenu = (e) => {
    if (menuOpen && !menuRef.current?.contains(e.target)) {
      setMenuOpen(false);
    }
  };
  const closeDropdownMenu = (e) => {
    if (dropdownOpen && !dropdownRef.current?.contains(e.target)) {
      setDropdownOpen(false);
    }
  };
  const closeAboutDropdownMenu = (e) => {
    if (aboutDropdownOpen && !aboutDropdownRef.current?.contains(e.target)) {
      setAboutDropdownOpen(false);
    }
  };
  const closeTourDropdownMenu = (e) => {
    if (tourDropdownOpen && !tourDropdownRef.current?.contains(e.target)) {
      setTourDropdownOpen(false);
    }
  };
  const closeFaqDropdownMenu = (e) => {
    if (faqDropdownOpen && !faqDropdownRef.current?.contains(e.target)) {
      setFaqDropdownOpen(false);
    }
  };
  const closeProfileDropdownMenu = (e) => {
    if (
      profileDropdownOpen &&
      !profileDropdownRef.current?.contains(e.target)
    ) {
      setProfileDropdownOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", closeOpenMenu);
    document.addEventListener("mousedown", closeDropdownMenu);
    document.addEventListener("mousedown", closeAboutDropdownMenu);
    document.addEventListener("mousedown", closeTourDropdownMenu);
    document.addEventListener("mousedown", closeFaqDropdownMenu);
    document.addEventListener("mousedown", closeProfileDropdownMenu);

    return () => {
      document.removeEventListener("click", closeOpenMenu);
      document.removeEventListener("mousedown", closeDropdownMenu);
      document.removeEventListener("mousedown", closeAboutDropdownMenu);
      document.removeEventListener("mousedown", closeTourDropdownMenu);
      document.removeEventListener("mousedown", closeFaqDropdownMenu);
      document.removeEventListener("mousedown", closeProfileDropdownMenu);
    };
  }, [
    menuOpen,
    dropdownOpen,
    aboutDropdownOpen,
    tourDropdownOpen,
    faqDropdownOpen,
    profileDropdownOpen,
  ]);

  const handleCoursesClick = () => {
    navigate("/courses");
  };

  const handleCourseCategoryClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleAboutDropdownClick = () => {
    setAboutDropdownOpen(!aboutDropdownOpen);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  useEffect(() => {
    if (hoveredCategory !== "") {
      const hoverCourses = allCourses.filter(
        (entity) => entity.courseCategory._id === hoveredCategory,
      );
      hoverCourses.sort((a, b) => {
        return a.order - b.order;
      })
      const tempCourseMapping = {}
      hoverCourses.forEach((course) => {
        tempCourseMapping[course.title] = courseMapping[course.title]
      })
      setHoveredCourses(tempCourseMapping);
    }
  }, [hoveredCategory, allCourses, courseMapping]);

  return (
    <>
      <div className="md:tw-hidden tw-block">
        <MenuSideBar role={userRole} />
      </div>
      <div className="md:tw-block tw-hidden">
        {userRole === "admin" || userRole === "superAdmin" ? (
          <AdminNavbar />
        ) : (
          <div className="tw-container ">
            <div
              className="tw-bg-lightest tw-border-primary tw-text-primary tw-fixed tw-w-full tw-top-0 tw-p-8 tw-px-4 md:tw-px-32 tw-flex tw-justify-between tw-items-center tw-z-50 tw-h-32"
              ref={menuRef}
            >
              {/* Responsive Menu Icon */}
              <div
                className="md:tw-hidden tw-cursor-pointer"
                onClick={toggleMenu}
              >
                <CiMenuBurger className="tw-w-6 tw-h-6 tw-text-primary" />
              </div>
              <div className="tw-flex tw-items-center">
                {/* image from public folder */}
                <img
                  src="https://images.ctfassets.net/fxuhbm32k7qv/3AEHDcAIZqKsSXdZJS6RQo/45488d768f431f846ed226468dee089f/White_bg__Horizontal_logo__300_150.png"
                  alt="100FoldsAcademy"
                  className=" tw-ml-16 tw-mt-2 tw-cursor-pointer"
                  onClick={() => navigate("/")}
                />
                {/* <div className="tw-text-lg tw-font-bold tw-mr-4">
                  100FoldsAcademy
                </div> */}
              </div>
              {/* Links on the Right */}
              <div className="tw-hidden md:tw-flex tw-space-x-3 ">
                {isLoggedIn && (
                  <>
                    <div
                      className="hover:tw-bg-accent hover:tw-rounded-3xl  tw-text-primary tw-cursor-pointer tw-px-4 tw-py-2 tw-w-full tw-text-center  tw-flex tw-items-center tw-relative tw-justify-center"
                      onClick={() => navigate("/cart")}
                    >
                      Cart{" "}
                      <FaShoppingCart className="tw-w-6 tw-h-6 tw-text-primary " />
                      <div className="tw-absolute tw-top-2 tw-right-3 tw--mt-1 tw--mr-1 tw-bg-secondary tw-text-white tw-w-4 tw-h-4 tw-flex tw-items-center tw-justify-center tw-rounded-3xl">
                        {cartSize}
                      </div>
                    </div>
                    <CustomToolTipButton
                      content={
                        userDetails.firstName + " " + userDetails.lastName
                      }
                      placement="bottom"
                    >
                      <button
                        className="hover:tw-bg-accent hover:tw-rounded-3xl tw-text-primary tw-cursor-pointer tw-px-4 tw-py-2 tw-w-full tw-text-center tw-flex tw-justify-center tw-items-center"
                        onClick={() => {
                          navigate("/profile");
                        }}
                      >
                        Profile
                      </button>
                    </CustomToolTipButton>

                    <div
                      className="hover:tw-bg-accent hover:tw-rounded-3xl tw-text-primary tw-cursor-pointer tw-px-4 tw-py-2 tw-w-full tw-text-center tw-flex tw-justify-center tw-items-center"
                      onClick={handleLogout}
                    >
                      Logout
                    </div>
                  </>
                )}
                {/* Login Icon */}
                {!isLoggedIn && (
                  <>
                    <div
                      className="hover:tw-bg-accent  hover:tw-rounded-3xl tw-text-primary tw-cursor-pointer tw-px-4 tw-py-2 tw-w-full tw-text-center  tw-flex tw-items-center tw-relative tw-justify-center"
                      onClick={() => navigate("/cart")}
                    >
                      Cart{" "}
                      <FaShoppingCart className="tw-w-6 tw-h-6 tw-text-primary " />
                      <div className="tw-absolute tw-top-2 tw-right-3 tw--mt-1 tw--mr-1 tw-bg-secondary tw-text-white tw-w-4 tw-h-4 tw-flex tw-items-center tw-justify-center tw-rounded-full">
                        {cartSize}
                      </div>
                    </div>
                    <div
                      className="hover:tw-bg-accent hover:tw-rounded-3xl tw-text-primary tw-cursor-pointer tw-px-4 tw-py-2 tw-w-full tw-text-center tw-flex tw-justify-center tw-items-center"
                      onClick={() => navigate("/login")}
                    >
                      Login
                    </div>

                    <div
                      className="hover:tw-bg-accent hover:tw-rounded-3xl tw-text-primary tw-cursor-pointer tw-px-4 tw-py-2 tw-w-full tw-text-center tw-flex tw-justify-center tw-items-center"
                      onClick={() => navigate("/register")}
                    >
                      SignUp
                    </div>
                  </>
                )}

                {/* Contact Icon */}
                <div
                  className="tw-bg-bright hover:tw-bg-accent hover:tw-rounded-3xl tw-whitespace-nowrap tw-text-primary tw-text-xl tw-cursor-pointer tw-px-4 tw-py-2 tw-w-full tw-text-center tw-flex tw-justify-center tw-items-center tw-rounded-3xl"
                  onClick={() => navigate("/contact-us")}
                >
                  Contact Us
                </div>
              </div>
            </div>

            {menuOpen ? (
              <MenuSideBar role={userRole} />
            ) : (
              <>
                <div
                  className={`md:tw-flex tw-shadow-lg tw-hidden tw-pt-2 tw-mx-auto tw-bg-lightest tw-border tw-text-primary tw-font-medium tw-fixed tw-w-full tw-top-32 tw-p-2 tw-flex tw-justify-center tw-items-center ${profileDropdownOpen ? "tw-z-10" : "tw-z-50"}`}
                >
                  <div className="tw-flex md:tw-space-x-16 tw-space-x-4 tw-text-lg">
                    <div
                      className=" md:tw-block tw-hidden hover:tw-text-primary tw-cursor-pointer hover:tw-bg-accent hover:tw-rounded-3xl tw-p-3"
                      onClick={() => navigate("/")}
                    >
                      Home
                    </div>
                    {/* About Dropdown */}
                    <div
                      className="tw-group tw-flex tw-flex-row tw-items-center tw-justify-center"
                      ref={aboutDropdownRef}
                    >
                      <div className="md:tw-block tw-hidden hover:tw-text-primary tw-cursor-pointer hover:tw-bg-accent  hover:tw-rounded-3xl tw-p-3">
                        About Us
                      </div>

                      <div className="tw-relative">
                        {aboutDropdownOpen ? (
                          <IoIosArrowDropupCircle
                            className="tw-w-6 tw-h-6 tw-text-primary tw-ml-2 tw-cursor-pointer"
                            onClick={handleAboutDropdownClick}
                          />
                        ) : (
                          <IoIosArrowDropdownCircle
                            className="tw-w-6 tw-h-6 tw-text-primary tw-ml-2 tw-cursor-pointer"
                            onClick={handleAboutDropdownClick}
                          />
                        )}
                        {aboutDropdownOpen && (
                          <div
                            className="tw-absolute tw-bg-lighter  tw-text-primary tw-text-left tw-py-2 tw-space-y-2 tw-w-48 tw-left-1/2 tw-transform tw--translate-x-1/2 tw-shadow-xl tw-rounded-md"
                            ref={aboutDropdownRef}
                          >
                            <div
                              className="hover:tw-bg-accent tw-cursor-pointer tw-pl-4 tw-py-2 tw-w-full "
                              onClick={() => {
                                navigate("/about-academy");
                                setAboutDropdownOpen(false);
                              }}
                            >
                              The 100Folds Academy
                            </div>
                            <div
                              className="hover:tw-bg-accent tw-cursor-pointer tw-pl-4 tw-py-2 tw-w-full "
                              onClick={() => {
                                navigate("/about-academy");
                                const checkSectionExistence = setInterval(() => {
                                  const sectionElement = document.getElementById("our-vision-mission");
                                  if (sectionElement) {
                                    clearInterval(checkSectionExistence);
                                    sectionElement.scrollIntoView({ behavior: "smooth" });
                                    setAboutDropdownOpen(false);
                                  }
                                }, 100);
                              }}
                            >
                              Our Vision & Mission
                            </div>
                            <div
                              className="hover:tw-bg-accent tw-cursor-pointer tw-pl-4 tw-py-2 tw-w-full"
                              onClick={() => {
                                navigate("/chefs");
                                setAboutDropdownOpen(false);
                              }}
                            >
                              Our Faculty
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Courses Dropdown */}
                    <div
                      className="tw-group tw-flex tw-flex-row tw-items-center"
                      ref={dropdownRef}
                    >
                      <div
                        className="md:tw-block tw-hidden hover:tw-text-primary tw-cursor-pointer tw-bg-bright hover:tw-bg-accent  tw-rounded-3xl tw-p-3"
                        onClick={handleCoursesClick}
                      >
                        Explore Our Courses
                      </div>
                      <div className="tw-relative">
                        {dropdownOpen ? (
                          <IoIosArrowDropupCircle
                            className="tw-w-6 tw-h-6 tw-text-primary tw-ml-2 tw-cursor-pointer"
                            onClick={handleCourseCategoryClick}
                          />
                        ) : (
                          <IoIosArrowDropdownCircle
                            className="tw-w-6 tw-h-6 tw-text-primary tw-ml-2 tw-cursor-pointer"
                            onClick={handleCourseCategoryClick}
                          />
                        )}
                        {dropdownOpen && (
                          <div className="tw-absolute tw-bg-lighter  tw-text-primary tw-py-2 tw-space-y-2 tw-w-52 -tw-left-4 tw-transform tw--translate-x-3/4 tw-shadow-xl tw-rounded-md">
                            {categories.map((category) => (
                              <div
                                key={category._id}
                                className=" tw-cursor-pointer tw-pl-3 tw-pt-2 tw-w-full tw-text-left"
                                onClick={() => {
                                  navigate(`/courses/category/${category._id}`);
                                  setDropdownOpen(false);
                                }}
                                onMouseEnter={() =>
                                  setHoveredCategory(category._id)
                                }
                                onMouseLeave={() => setHoveredCategory(null)}
                              >
                                {category.title}
                                {hoveredCategory === category._id && (
                                  <div
                                    className="tw-flex tw-flex-col tw-text-left tw-px-4 tw-pt-2 tw-w-full"
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    <div className="tw-text-right">
                                      {Object.entries(hoveredCourses).map(([title, course]) => (
                                        <div
                                          key={title}
                                          className="tw-text-sm tw-cursor-pointer tw-text-left  tw-p-2 tw-bg-transparent hover:tw-bg-accent"
                                          onClick={() => {
                                            if (hoveredCourses[title].length === 1) {
                                              navigate(`/courses/${title}`)
                                            } else {
                                              navigate(`/courses/title/${title}`)
                                            }
                                            setDropdownOpen(false);
                                          }}
                                        >
                                          {title}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                   
                    {/*<div
                          className=" md:tw-block tw-hidden hover:tw-text-primary tw-cursor-pointer hover:tw-bg-accent  hover:tw-rounded-3xl tw-p-3"
                      onClick={() => navigate("/gallery")}
                    >
                      Gallery
                    </div>*/}
                    <div
                      className="md:tw-block tw-hidden hover:tw-text-primary tw-cursor-pointer hover:tw-bg-accent  hover:tw-rounded-3xl tw-p-3"
                      onClick={() => navigate("/news-events")}
                    >
                      Updates & Blogs{" "}
                    </div>
                    {/* <div
                    className="md:tw-block tw-hidden hover:tw-text-primary tw-cursor-pointer hover:tw-underline tw-decoration-primary tw-decoration-4 tw-underline-offset-8"
                    onClick={() => navigate("/blogs")}
                  >
                    Blogs
                  </div> */}

                    {/*Faq and Policies Dropdown */}
                    <div
                      className="tw-group tw-flex tw-flex-row tw-items-center"
                      ref={faqDropdownRef}
                    >
                      <div className="md:tw-block tw-hidden hover:tw-text-primary tw-cursor-pointer hover:tw-bg-accent  hover:tw-rounded-3xl tw-p-3">
                        FAQs & Policies
                      </div>
                      <div className="tw-relative">
                        {faqDropdownOpen ? (
                          <IoIosArrowDropupCircle
                            className="tw-w-6 tw-h-6 tw-text-primary tw-ml-2 tw-cursor-pointer "
                            onClick={() => setFaqDropdownOpen(!faqDropdownOpen)}
                          />
                        ) : (
                          <IoIosArrowDropdownCircle
                            className="tw-w-6 tw-h-6 tw-text-primary tw-ml-2 tw-cursor-pointer"
                            onClick={() => setFaqDropdownOpen(!faqDropdownOpen)}
                          />
                        )}
                        {faqDropdownOpen && (
                          <div className="tw-absolute tw-bg-lighter tw-text-primary tw-py-2 tw-space-y-2 tw-w-48 tw-left-1/2 tw-top-8 tw-transform tw--translate-x-32 tw-shadow-xl tw-rounded-md">
                            <div
                              className="hover:tw-bg-accent tw-cursor-pointer tw-px-4 tw-py-2 tw-w-full tw-text-left"
                              onClick={() => {
                                navigate("/faq");
                                setFaqDropdownOpen(false);
                              }}
                            >
                              FAQs
                            </div>
                            <div
                              className="hover:tw-bg-accent tw-cursor-pointer tw-px-4 tw-py-2 tw-w-full tw-text-left"
                              onClick={() => {
                                navigate("/policies");
                                setFaqDropdownOpen(false);
                              }}
                            >
                              Policies
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default NavbarComponent;
